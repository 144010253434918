// const subTitle = "About English Empire";
const title = "Welcome to English Empire";
const desc =
  "English Empire is a premier language training institute located in Kerala, offeringa wide range of courses designed to enhance your language skills and empoweryou for success. With programs such as Residential English Camps, BusinessEnglish, Teachers Training, Voice and Accent Training, and exam preparation forIELTS, OET, PTE, and TOEFL, we cater to professionals, teachers,businesspeople, doctors, and students aspiring to study abroad. At EnglishEmpire, we pride ourselves on our experienced trainers with cutting-edgemodules, and commitment to delivering customised training to every individual.Our mission is to help people communicate better without compromising onquality, and our website reflects our commitment to international standards.";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.jpg",

    title: "Experienced Trainers",
    desc: "we pride ourselves on our experienced trainers, international-level study environment, and commitment",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",

    title: "Get Certificate",
    desc: "We cater to teachers, business professionals, doctors, and students aspiring to study abroad",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",

    title: "Many Courses",
    desc: "Wide range of courses designed to enhance your language skills and empower you for success",
  },
];

const UnderBanner = () => {
  return (
    <div className="about-section2">
      <div className="container">
        <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
          <div className="col">
            <div className="about-right padding-tb">
              <div className="section-header">
                {/* <span className="subtitle">{subTitle}</span> */}
                <h2 className="title">{title}</h2>
                <div style={{ height: 10 }}></div>
                <p>{desc}</p>
              </div>
              <div className="section-wrapper">
                <ul className="lab-ul">
                  {/* {aboutList.map((val, i) => (
                    <li key={i}>
                      <div className="sr-left">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="sr-right">
                        <h5>{val.title}</h5>
                        <p>{val.desc}</p>
                      </div>
                    </li>
                  ))} */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="about-left">
              <div className="about-thumb">
                <img src="assets/images/about/girl2.png" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderBanner;
