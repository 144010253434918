const subTitle = "About English Empire";
const title = "Good Qualification Services And Better Skills";
const desc =
  "English Empire is a premier language training institute located in Kerala, offering a wide range of courses designed to enhance your language skills and empower you for success.";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.jpg",

    title: "Experienced Trainers",
    desc: "we pride ourselves on our experienced trainers, international-level study environment, and commitment",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",

    title: "Get Certificate",
    desc: "We cater to teachers, business professionals, doctors, and students aspiring to study abroad",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",

    title: "Many Courses",
    desc: "Wide range of courses designed to enhance your language skills and empower you for success",
  },
];

const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
          <div className="col">
            <div className="about-right padding-tb">
              <div className="section-header">
                <span className="subtitle">{subTitle}</span>
                <h2 className="title">{title}</h2>
                <p>{desc}</p>
              </div>
              <div className="section-wrapper">
                <ul className="lab-ul">
                  {aboutList.map((val, i) => (
                    <li key={i}>
                      <div className="sr-left">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="sr-right">
                        <h5>{val.title}</h5>
                        <p>{val.desc}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="about-left">
              <div className="about-thumb">
                <img src="assets/images/about/01.png" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
