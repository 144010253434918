import { FloatingWhatsApp } from "react-floating-whatsapp";

const subTitle = "ENGLISH EMPIRE";
const title = (
  <h1 className="title">
    <span className="d-lg-block">
      Unlock Your Language <br />
    </span>
    Potential with <span className="d-lg-block">Us!</span>
  </h1>
);
const desc =
  "Empower yourself with effective communication skills that open doors to endless opportunities.";

const catagoryList = [
  {
    name: "Figma",
    link: "#",
  },
  {
    name: "Adobe XD",
    link: "#",
  },
  {
    name: "illustration",
    link: "#",
  },
  {
    name: "Photoshop",
    link: "#",
  },
];

const shapeList = [
  {
    name: "16M Students Happy",
    link: "#",
    className: "ccl-shape shape-1",
  },
  {
    name: "130K+ Total Courses",
    link: "#",
    className: "ccl-shape shape-2",
  },
  {
    name: "89% Successful Students",
    link: "#",
    className: "ccl-shape shape-3",
  },
  {
    name: "23M+ Learners",
    link: "#",
    className: "ccl-shape shape-4",
  },
  {
    name: "36+ Languages",
    link: "#",
    className: "ccl-shape shape-5",
  },
];

const Banner = () => {
  return (
    <section className="banner-section">
      <FloatingWhatsApp accountName="English Empire Help Desk" avatar="assets/images/logo/avatar.png" phoneNumber="+918848157764"/>
      <div className="container" style={{height:600}}>
        <div className="section-wrapper">
          <div className="row align-items-center">
            <div className="col-xxl-5 col-xl-6 col-lg-10">
              <div className="banner-content" style={{ paddingBottom: 150 }}>
                {/* style={{paddingBottom:100}} */}
                <h6
                  className="subtitle text-uppercase fw-medium"
                  style={{ fontSize: 30 }}
                >
                  {subTitle}
                </h6>
                <h1 style={{ fontSize: 50 }}>{title}</h1>
                <p className="desc" style={{ fontSize: 25 }}>
                  {desc}
                </p>
                <a href="https://wa.me/+918848157764" target="_blank">
                  {" "}
                  <button
                    type="submit"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor: "#25D366",
                    }}
                  >
                    <span>
                      <img
                        src="https://www.dlf.pt/dfpng/maxpng/6-66817_whatsapp-png.png"
                        alt=""
                        style={{ height: 20 }}
                      />
                      &nbsp;&nbsp; WhatsApp
                    </span>
                  </button>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="tel:+918848157764" target="_blank">
                  {" "}
                  <button
                    type="submit"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor: "#007bff",
                    }}
                  >
                    <span>
                      <img
                        src="https://www.dlf.pt/dfpng/maxpng/425-4258321_telephone-icon-png.png"
                        alt=""
                        style={{ height: 20 }}
                      />
                      &nbsp;&nbsp; Call us Now
                    </span>
                  </button>
                </a>
                {/* <form action="/">
                                    <div className="banner-icon">
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="Keywords of your course" />
                                    <button type="submit">Search Course</button>
                                </form>
                                <div className="banner-catagory d-flex flex-wrap">
                                    <p>Most Popular : </p>
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {catagoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul>
                                </div> */}
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <div className="banner-thumb">
                <img
                  src="assets/images/banner/girl.png"
                  alt="img"
                  style={{ paddingRight: 50 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-shapes" style={{ marginRight: 80 }}></div>
      <div className="cbs-content-list d-none">
        <ul className="lab-ul">
          {shapeList.map((val, i) => (
            <li className={val.className} key={i}>
              <a href={val.link}>{val.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Banner;
