import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
// import Achievement from "../component/section/achievement";
import Banner from "../component/section/banner";
// import Blog from "../component/section/blog";
import Clients from "../component/section/clients";
// import Category from "../component/section/category";
import Feature from "../component/section/feature";
import Course from "../component/section/course";
import Instructor from "../component/section/instructor";
// import Sponsor from "../component/section/sponsor";
import EventTwo from "../component/section/event-2";
import ClientsTwo from "../component/section/clients-2";
import UnderBanner from "../component/section/under_banner";

const Home = () => {
  const isMobile = window.innerWidth <= 500;
  console.log(isMobile);
  return (
    <Fragment>
      <Header />
      <Banner />
      {isMobile ? <div></div> : <div style={{ height: 30 }}></div>}
      <UnderBanner />
      {/* <Sponsor /> */}
      {/* <Feature /> */}
      <Clients />
      <Course />
      <About />
      <Instructor />
      <EventTwo />
      <ClientsTwo />
      {/* <Blog /> */}
      {/* <Clients/> */}
      {/* <Achievement /> */}
      <Footer />
    </Fragment>
  );
};

export default Home;
