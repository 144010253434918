import { Component, Fragment, useEffect, useState } from "react";
import Footer from "../layout/footer";
import Header from "../layout/header";
import PageHeader from "../layout/pageheader";
import { getDocs, collection } from "firebase/firestore";
import { getDb } from "../../firebase_init";

const Gallery = () => {
  const collection_name = "gallery";
  const [countries, setCountries] = useState([]);

  const findAll = async () => {
    const doc_refs = await getDocs(collection(getDb(), collection_name));

    const res = [];

    doc_refs.forEach((country) => {
      res.push({
        id: country.id,
        ...country.data(),
      });
    });

    return res;
  };

  const fetchData = async () => {
    const res = await findAll();

    setCountries([...res]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Gallery"} curPage={"Gallery"} />
      <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
        {countries.map((val, i) => (
          <div className="col" key={i}>
            <div className="instructor-inner">
              <div className="instructor-thumb">
                <img src={`${val.image_url}`} alt={`${"English Empire"}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ height: 100 }}></div>

      <Footer />
    </Fragment>
  );
};

export default Gallery;
