import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const subTitle = "";
const title = "What People Say About Us";

const clientList = [
  {
    imgUrl: "assets/images/event/01.jpg",
    imgAlt: "education thumb rajibraj91 rajibraj",
    desc: "I had an incredible time at English Camp, and I owe a big thanks to the amazing trainers like Abdu Rahman sir and Gafoor sir. They guided us through grammar, vocabulary, and numerous English lessons. The TPR game made learning exciting, and the trip was unforgettable. Thank you so much English Empire",
    name: "Muhammad Shafi",
    degi: "Student",
  },
  {
    imgUrl: "assets/images/clients/02.jpg",
    imgAlt: "education thumb rajibraj91 rajibraj",
    desc: "I finished a one-month online course with English Empire, and it was great! The lessons were easy to understand, and I could learn comfortably at home. The teachers were nice and helped me a lot. Thanks to this course, I'm much better at English, and I'm not shy to speak anymore. Thank you, English Empire!",
    name: "Shareena",
    degi: "Student",
  },
  {
    imgUrl: "assets/images/clients/03.jpg",
    imgAlt: "education thumb rajibraj91 rajibraj",
    desc: "I'm deeply thankful to Masna ma'am and the dedicated team at English Empire for giving me the chance to improve my English skills. This one-month online course with live sessions and call activities was a fantastic experience. Thanks to the individual attention and support from the faculty, I've gained the confidence to speak confidently in English. I'm truly appreciative of English Empire's excellent course.",
    name: "Manikandan",
    degi: "Student",
  },
];

const ClientsTwo = () => {
  return (
    <div className="clients_section padding-tb">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="clients-slider overflow-hidden">
            <div className="swiper-wrapper">
              <Swiper
                spaceBetween={20}
                slidesPerView={2}
                loop={"true"}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  0: {
                    width: 0,
                    slidesPerView: 1,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 2.8,
                  },
                }}
              >
                {clientList.map((val, i) => (
                  <SwiperSlide key={i}>
                    <div className="client-item">
                      <div className="client-inner" style={{backgroundColor:"#e3f2fd"}}>
                        {/* <div className="client-thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div> */}
                        <div className="client-content">
                          <p>{val.desc}</p>
                          <div className="client-info">
                            <h6 className="client-name">{val.name}</h6>
                            <span className="client-degi">{val.degi}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsTwo;
