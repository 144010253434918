import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Achievement from "../component/section/achievement";
import Student from "../component/section/student";

const subTitle = "Learn from English Empire";
const title = "Discover Your Potential. Make A Global Impact.";
// const btnText = "Browse All Categories";

let categoryItemList = [
    {
        imgUrl: 'assets/images/category/icon/15.jpg',
        imgAlt: 'category',
        title: 'Expertise',
        desc: ' Benefit from our team of experienced trainers who bring extensive knowledge and expertise to help you succeed.',
    },
    {
        imgUrl: 'assets/images/category/icon/16.jpg',
        imgAlt: 'category',
        title: 'Excellence',
        desc: 'Experience exceptional training programs designed to deliver the highest standards of education and learning outcomes.',
    },
    {
        imgUrl: 'assets/images/category/icon/17.jpg',
        imgAlt: 'category',
        title: 'Customization',
        desc: 'Personalised attention and customised training plans that cater to your specific language learning needs and objectives.',
    },
    {
        imgUrl: 'assets/images/category/icon/17.jpg',
        imgAlt: 'category',
        title: 'Quality',
        desc: 'Trust in our dedication to maintaining the highest standards of education, instruction, and student support.',
    },
    {
        imgUrl: 'assets/images/category/icon/17.jpg',
        imgAlt: 'category',
        title: 'Results',
        desc: 'Our proven track record of helping students achieve their language proficiency targets and reach outcomes.',
    },
]



const InstructorPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Why Choose Us'} curPage={'instructor'} />
            <div className="category-section padding-tb section-bg style-3">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            {categoryItemList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="category-item text-center">
                                        <div className="category-inner">
                                            <div className="category-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="category-content">
                                                <Link to="/signup"><h4>{val.title}</h4></Link>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div className="text-center mt-5">
                            <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <Student />
            <Achievement /> */}
            <Footer />
        </Fragment>
    );
}
 
export default InstructorPage;