import { collection, getDoc, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { getDb } from "../../firebase_init";

const Header = () => {
  // FIREBASE section

  const contact_info_collection = "contactInfo";
  const social_media_collection = "socialMedia";

  const [contactInfoData, setContactInfoData] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);

  const findAll = async () => {
    const contact_info_doc_refs = await getDocs(
      collection(getDb(), contact_info_collection)
    );
    const social_media_doc_refs = await getDocs(
      collection(getDb(), social_media_collection)
    );

    const contact_res = [];
    const social_res = [];

    contact_info_doc_refs.forEach((country) => {
      contact_res.push({
        id: country.id,
        ...country.data(),
      });
    });

    social_media_doc_refs.forEach((country) => {
      social_res.push({
        id: country.id,
        ...country.data(),
      });
    });

    return [contact_res, social_res];
  };

  const fetchData = async () => {
    const res = await findAll();

    setContactInfoData([...res[0]]);
    setSocialMediaData([...res[1]]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // constants
  let address =
    contactInfoData[0] == undefined ? "" : contactInfoData[0].address;
  let phoneNumber =
    contactInfoData[0] == undefined ? "" : contactInfoData[0].phone;

  let socialList = [
    {
      iconName: "icofont-instagram",
      siteLink:
        socialMediaData[0] == undefined ? "" : socialMediaData[0].instagram,
    },
    {
      iconName: "icofont-linkedin",
      siteLink:
        socialMediaData[0] == undefined ? "" : socialMediaData[0].linkedin,
    },
    {
      iconName: "icofont-youtube",
      siteLink:
        socialMediaData[0] == undefined ? "" : socialMediaData[0].youtube,
    },
  ];

  // state management
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p>Find us on : </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink}>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                <img
                  src="assets/images/logo/ss.png"
                  alt="logo"
                  style={{ height: 50 }}
                />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <a href="/">Home</a>
                    {/* <NavLink to="/">Home </NavLink> */}
                    {/* <ul className="lab-ul dropdown-menu"> 
                                           
                                            <li><NavLink to="/index-2">Home Two</NavLink></li>
                                            <li><NavLink to="/index-3">Home Three</NavLink></li>
                                            <li><NavLink to="/index-4">Home Four</NavLink></li>
                                            <li><NavLink to="/index-5">Home Five</NavLink></li>
                                            <li><NavLink to="/index-6">Home Six</NavLink></li>
                                            <li><NavLink to="/index-7">Home Seven</NavLink></li>
                                        </ul>  */}
                  </li>
                  {/* <li>
                    <a href="/about">About</a>
                  </li> */}

                  <li>
                    <NavLink to="/about">About</NavLink>
                  </li>
                  {/* <li>
                    <a href="/course">Courses</a>
                  </li> */}

                  <li>
                    <NavLink to="/course">Courses</NavLink>
                  </li>

                  {/* <ul className="lab-ul dropdown-menu"> */}
                  {/* <li><NavLink to="/course">Course</NavLink></li> */}
                  {/* <li><NavLink to="/course-single">Course Details</NavLink></li>
                                            <li><NavLink to="/course-view">Course View</NavLink></li> */}
                  {/*         
                                        </ul> */}
                  {/* <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Blog</a>
                                        <ul className="lab-ul dropdown-menu">
                                            <li><NavLink to="/blog">Blog Grid</NavLink></li>
                                            <li><NavLink to="/blog-2">Blog Style 2</NavLink></li>
                                            <li><NavLink to="/blog-3">Blog Style 3</NavLink></li>
                                            <li><NavLink to="/blog-single">Blog Single</NavLink></li>
                                        </ul>
                                    </li> */}
                  {/* <li className="menu-item-has-children">
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Pages</a> */}

                  <li>
                    <NavLink to="/team">Team</NavLink>
                  </li>
                  {/* <li><NavLink to="/instructor">Instructor</NavLink></li> */}
                  {/* <li><NavLink to="/shop">Shop Page</NavLink></li>
                                            <li><NavLink to="/shop-single">Shop Details Page</NavLink></li>
                                            <li><NavLink to="/cart-page">Shop Cart Page</NavLink></li>
                                            <li><NavLink to="/search-page">Search Page</NavLink></li>
                                            <li><NavLink to="/search-none">Search None</NavLink></li> */}
                  {/* <li><NavLink to="/404">404</NavLink></li> */}

                  {/* </li> */}
                  <li>
                    <NavLink to="/gallery">Gallery</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
              {/*                             
                            <Link to="/login" className="login"><i className="icofont-user"></i> <span>LOG IN</span> </Link>
                            <Link to="/signup" className="signup"><i className="icofont-users"></i> <span>SIGN UP</span> </Link> */}

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
