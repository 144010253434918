import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Rating from "../component/sidebar/rating";
import { collection, getDocs } from "firebase/firestore";
import { getDb } from "../firebase_init";

const instructorList = [
  {
    imgUrl: "assets/images/instructor/Anisha Jose.png",
    imgAlt: "Image",
    name: "Anisha Jose",
    degi: "Instructor",
    courseCount: "08 courses",
    studentAnroll: "30 students",
  },

  {
    imgUrl: "assets/images/instructor/Fidha.png",
    imgAlt: "Image",
    name: "Fidha",
    degi: "Instructor",
    courseCount: "08 courses",
    studentAnroll: "30 students",
  },
  {
    imgUrl: "assets/images/instructor/Baheej.png",
    imgAlt: "Image",
    name: "Baheej",
    degi: "Instructor",
    courseCount: "08 courses",
    studentAnroll: "30 students",
  },
  {
    imgUrl: "assets/images/instructor/Radhika.png",
    imgAlt: "Image",
    name: "Radhika",
    degi: "Instructor",
    courseCount: "08 courses",
    studentAnroll: "30 students",
  },
  {
    imgUrl: "assets/images/instructor/Labeeba.png",
    imgAlt: "Image",
    name: "Labeeba",
    degi: "Instructor",
    courseCount: "08 courses",
    studentAnroll: "30 students",
  },
];

const achieveList = [
  {
    imgUrl: "assets/images/achive/01.png",
    imgAlt: "achive thumb rajibraj91 rajibraj",
    title: "Start Learning Today",
    desc: "Empower yourself with effective communication skills that open doors to endless opportunities.",
    btnText: "Know More",
    siteLink: "/#/about",
  },
  {
    imgUrl: "assets/images/achive/02.png",
    imgAlt: "achive thumb rajibraj91 rajibraj",
    title: " Join Our Course",
    desc: "We offer a wide range of courses designed to enhance your language skills and empower you for success.",
    btnText: "Courses",
    siteLink: "/#/course",
  },
];

const TeamPage = () => {
  const collection_name = "teachers";
  const [countries, setCountries] = useState([]);

  const findAll = async () => {
    const doc_refs = await getDocs(collection(getDb(), collection_name));

    const res = [];

    doc_refs.forEach((country) => {
      res.push({
        id: country.id,
        ...country.data(),
      });
    });

    return res;
  };

  const fetchData = async () => {
    const res = await findAll();

    setCountries([...res]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <Header />
      <PageHeader title={"All Team Members"} curPage={"Team"} />
      <div className="instructor-section padding-tb section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
              {countries.map((val, i) => (
                <div className="col" key={i}>
                  <div className="instructor-item">
                    <div className="instructor-inner">
                      <div className="instructor-thumb">
                        <img
                          src={`${val.image_url}`}
                          alt={`${"English Empire"}`}
                          style={{ width: "50%" }}
                        />
                      </div>
                      <div className="instructor-content">
                        <h4>{val.name}</h4>
                        <p>{val.role}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="achieve-part mt-5">
              <div className="row g-4 row-cols-1 row-cols-lg-2">
                {achieveList.map((val, i) => (
                  <div className="col" key={i}>
                    <div className="achieve-item">
                      <div className="achieve-inner">
                        <div className="achieve-thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div>
                        <div className="achieve-content">
                          <h4>{val.title}</h4>
                          <p>{val.desc}</p>
                          <a href={val.siteLink} className="lab-btn">
                            <span>{val.btnText}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TeamPage;
