import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const subTitle = "Don’t Miss the Day";
const title = "Upcomming Events";

const eventSliderList = [
  {
    imgUrl: "assets/images/event/tn.jpg",
    imgAlt: "event rajibraj91 rajibraj",
    Link: "https://www.youtube.com/watch?v=5v5rQyjXpOk",
    // title: 'Private Car',
    // price: '$329.00',
    // duration: '4 Weeks',
    btnText: "Play",
    servList: [
      {
        text: "Driving License",
      },
      {
        text: "15 Classroom Lessons",
      },
      {
        text: "10 In-Car Lessons",
      },
      {
        text: "Medical Service",
      },
      {
        text: "FREE final exam",
      },
      {
        text: "With MTO Certification",
      },
    ],
  },
  {
    imgUrl: "assets/images/event/tn1.jpg",
    imgAlt: "event rajibraj91 rajibraj",
    Link: "https://www.youtube.com/watch?v=tJZBWmKVntc",
    title: "Private Car",
    price: "$329.00",
    duration: "4 Weeks",
    btnText: "Play",
    servList: [
      {
        text: "Driving License",
      },
      {
        text: "15 Classroom Lessons",
      },
      {
        text: "10 In-Car Lessons",
      },
      {
        text: "Medical Service",
      },
      {
        text: "FREE final exam",
      },
      {
        text: "With MTO Certification",
      },
    ],
  },
  {
    imgUrl: "assets/images/event/tn3.jpg",
    imgAlt: "event rajibraj91 rajibraj",
    Link: "https://www.youtube.com/watch?v=z-wcc_KILxY",
    title: "Private Car",
    price: "$329.00",
    duration: "4 Weeks",
    btnText: "Play",
    servList: [
      {
        text: "Driving License",
      },
      {
        text: "15 Classroom Lessons",
      },
      {
        text: "10 In-Car Lessons",
      },
      {
        text: "Medical Service",
      },
      {
        text: "FREE final exam",
      },
      {
        text: "With MTO Certification",
      },
    ],
  },
  {
    imgUrl: "assets/images/event/tn4.jpg",
    imgAlt: "event rajibraj91 rajibraj",
    Link: "https://www.youtube.com/watch?v=YfcjkbUO2LI",
    title: "Private Car",
    price: "$329.00",
    duration: "4 Weeks",
    btnText: "Play",
    servList: [
      {
        text: "Driving License",
      },
      {
        text: "15 Classroom Lessons",
      },
      {
        text: "10 In-Car Lessons",
      },
      {
        text: "Medical Service",
      },
      {
        text: "FREE final exam",
      },
      {
        text: "With MTO Certification",
      },
    ],
  },
];

const EventTwo = () => {
  return (
    <div className="event-section style-2 padding-tb section-bg-ash">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle blue-color">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div
            className="event-navi-item event-slider-next"
            style={{ top: 100 }}
          >
            <i className="icofont-rounded-double-right"></i>
          </div>
          <div
            className="event-navi-item event-slider-prev"
            style={{ top: 100 }}
          >
            <i className="icofont-rounded-double-left"></i>
          </div>

          <div className="event-sliders overflow-hidden">
            <Swiper
              spaceBetween={0}
              slidesPerView={2}
              loop={"true"}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: ".event-slider-prev",
                nextEl: ".event-slider-next",
              }}
              modules={[Autoplay, Navigation]}
              breakpoints={{
                0: {
                  width: 0,
                  slidesPerView: 1,
                },
                680: {
                  width: 680,
                  slidesPerView: 2,
                },
                1180: {
                  width: 1180,
                  slidesPerView: 2.2,
                },
              }}
            >
              {eventSliderList.map((val, i) => (
                <SwiperSlide key={i}>
                  <div className="event-item style-2" >
                    <div className="event-inner" >
                      <center>
                        {" "}
                        <div className="event-thumb" style={{ }}>
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          <div
                            style={{
                              // paddingLeft: 220,
                              // paddingBottom: 100,
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              // width: "100px", // Adjust as needed
                              // height: "100px", // Adjust as needed
                              // backgroundColor: "lightgray",
                              alignSelf: "center",
                              textAlign: "center",
                              lineHeight: "200px",
                            }}
                          >
                            <a
                              href={`${val.Link}`}
                              className="video-button popup"
                              target="_blank"
                            >
                              <i className="icofont-ui-play"></i>
                            </a>
                          </div>
                        </div>
                      </center>{" "}
                      {/* <div className="event-content">
                                                <h5>{val.title}</h5>
                                                <h2>{val.price}</h2>
                                                <span>{val.duration}</span>
                                                <ul className="lab-ul">
                                                    {val.servList.map((val, i) => (
                                                        <li key={i}>{val.text}</li>
                                                    ))}
                                                </ul>
                                                <Link to="/login" className="lab-btn"><span>{val.btnText}</span></Link>
                                            </div> */}
                      {/* <div className="event-content">
                                                <br />
                                             <Link to="/login" className="lab-btn"><span>{val.btnText}</span></Link>


                                             </div> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTwo;
