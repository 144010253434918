


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps" style={{height:426}}>
              
              
              
                



                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1423.2213732725552!2d75.93136432579597!3d11.416236251239983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba669face53e2b9%3A0xd85116e3e28ea258!2sEnglish%20Empire!5e0!3m2!1sen!2sin!4v1690645949055!5m2!1sen!2sin"></iframe>
             
            </div>
        </div>
    );
}
 
export default GoogleMap;