import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";

const subTitle = "Featured Courses";
const title = "Pick A Course To Get Started";

const courseList = [
  {
    imgUrl: "assets/images/course/3.jpg",

    title: "Residential English Camps",

    btnText:
      "Immerse yourself in an intensive English learning experience with our residential camps, designed to boost your language proficiency while enjoying a supportive and immersive environment.",
  },
  {
    imgUrl: "assets/images/course/4.jpg",

    title: "Voice and Accent Training",

    btnText:
      "Master the art of clear and confident communication with our Voice and Accent Training. Our experienced trainers will help you refine your pronunciation, intonation, and overall speaking skills for better clarity and impact.",
  },

  // {
  //     imgUrl: 'assets/images/course/9.jpg',

  //     title: 'Exam Preparation',

  //     btnText: 'Prepare for internationally recognized language proficiency exams with our specialised training programs. We offer comprehensive coaching for IELTS, OET, PTE, and TOEFL, ensuring you are fully equipped to achieve your desired scores.',
  // },

  {
    imgUrl: "assets/images/course/9.jpg",

    title: "Business English",

    btnText:
      "Sharpen your language skills for the corporate world. Our Business English courses focus on professional communication,presentations, negotiations, and business writing, equipping you with the language tools to excel in your career.",
  },

  {
    imgUrl: "assets/images/course/2.jpg",

    title: "Teachers Training",

    btnText:
      "Enhance your teaching abilities with our specialised Teachers Training courses. Gain insights into effective language teaching methodologies, classroom management techniques, and language assessment strategies.",
  },

  {
    imgUrl: "assets/images/course/15.jpg",
    title: "Online Crash Course",
    btnText:
      "Join our Online Crash Course designed for individuals seeking a quick and intensive language learning experience. This course offers focused and accelerated training to enhance your language skills within a short period of time, allowing you to achieve rapid progress and results.",
  },

  {
    imgUrl: "assets/images/course/01.jpg",
    title: "Individual Spoken English Course",
    btnText:
      "Enhance your spoken English skills through our Individual Spoken English Course. Tailored to your specific needs, this course focuses on improving fluency, pronunciation, vocabulary, and conversation skills.",
  },
  {
    imgUrl: "assets/images/course/6.jpg",
    title: "IELTS Preparation",
    btnText:
      "Prepare for the IELTS (International English Language Testing System) exam with our comprehensive training program. Our expert instructors will guide you through all sections of the exam, including listening, reading, writing, and speaking, helping you achieve your desired IELTS score.",
  },
  {
    imgUrl: "assets/images/course/7.jpg",
    title: "OET Preparation",
    btnText:
      "Excel in the OET  (Occupational English Test) exam with our specialised preparation course. Designed for healthcare professionals, this program focuses on enhancing language skills specific to the medical field, ensuring your success in the OET exam.",
  },

  {
    imgUrl: "assets/images/course/8.jpg",
    title: "PTE Preparation",
    btnText:
      "Prepare for the PTE (Pearson Test of English) exam with our specialised training program. Our expert instructors will guide you through all sections of the PTE exam, including speaking, writing, reading, and listening, helping you gain the necessary skills and confidence to succeed.",
  },
];

const Course = () => {
  return (
    <div className="course-section padding-tb section-bg">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
            {courseList.map((val, i) => (
              <div className="col" key={i}>
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="course-content">
                      {/* <div className="course-price">{val.price}</div> */}
                      <div className="course-category">
                        {/* <div className="course-cate">
                                                    <a href="#">{val.cate}</a>
                                                </div> */}
                        <div className="course-reiew">
                          <Rating />
                          {/* <span className="ratting-count"> {val.reviewCount}</span> */}
                        </div>
                      </div>
                      <h4>{val.title}</h4>
                      {/* <Link to="/course-single"></Link> */}

                      <div className="course-details">
                        {/* <div className="couse-count"><i className="icofont-video-alt"></i> {val.totalLeson}</div>
                                                <div className="couse-topic"><i className="icofont-signal"></i> {val.schdule}</div> */}
                      </div>
                      <div className="course-footer">
                        {/* <div className="course-author">
                                                    <img src={`${val.authorImgUrl}`} alt={`${val.authorImgAlt}`} />
                                                    <Link to="/team-single" className="ca-name">{val.authorName}</Link>
                                                </div> */}
                        <div className="course-btn">
                          {/* <i className="icofont-external-link"></i> */}

                          <div style={{ fontSize: 17.06 }}>{val.btnText} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
