import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


let db = false;

export const getDb = () => {
  if (!db) {
    const firebaseConfig = {
      apiKey: "AIzaSyAzqhYF7mZ2vybYJ8RZPghFGAfqkmyRWUc",
      authDomain: "english-empire.firebaseapp.com",
      projectId: "english-empire",
      storageBucket: "english-empire.appspot.com",
      messagingSenderId: "838202613308",
      appId: "1:838202613308:web:c62081dc079d30716fed39",
    }

    const app = initializeApp(firebaseConfig)

    db = getFirestore(app)
  }

  return db
}

