import { Link } from "react-router-dom";

//  const subTitle = "Why Choose Us";
const title = "Why Choose English Empire";
// const btnText = "Browse All Categories";

let categoryItemList = [
  {
    imgUrl: "assets/images/category/icon/Expertise.png",
    imgAlt: "category",
    title: "Expertise",
    desc: " Benefit from our team of experienced trainers who bring extensive knowledge and expertise to help you succeed.",
  },
  {
    imgUrl: "assets/images/category/icon/Excellence.png",
    imgAlt: "category",
    title: "Excellence",
    desc: "Experience exceptional training programs designed to deliver the highest standards of education and learning outcomes.",
  },
  {
    imgUrl: "assets/images/category/icon/Customization.png",
    imgAlt: "category",
    title: "Customization",
    desc: "Personalised attention and customised training plans that cater to your specific language learning needs and objectives.",
  },
  {
    imgUrl: "assets/images/category/icon/Excellence.png",
    imgAlt: "category",
    title: "Quality",
    desc: "Trust in our dedication to maintaining the highest standards of education, instruction, and student support.",
  },
  {
    imgUrl: "assets/images/category/icon/Results.png",
    imgAlt: "category",
    title: "Results",
    desc: "Our proven track record of helping students achieve their language proficiency targets and reach outcomes.",
  },
  {
    imgUrl: "assets/images/category/icon/Flexible.png",
    imgAlt: "category",
    title: "Flexible",
    desc: "Adapt your learning journey with our flexible programs, tailored to accommodate your unique schedule and preferences.",
  },
];

const Clients = () => {
  return (
    <div className="category-section padding-tb section-bg style-2">
      <div className="container">
        <div className="section-header text-center">
          {/* <span className="subtitle">{subTitle}</span> */}
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
            {categoryItemList.map((val, i) => (
              <div className="col-10" key={i}>
                <div className="category-item text-center">
                  <div className="category-inner">
                    <div className="category-thumb">
                      <img
                        src={`${val.imgUrl}`}
                        alt={`${val.imgAlt}`}
                        style={{ width: 100 }}
                      />
                    </div>
                    <div className="category-content">
                      <Link to="/about">
                        <h4>{val.title}</h4>
                      </Link>
                      <p style={{ fontWeight: 700 }}>{val.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="text-center mt-5">
                            <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Clients;
